import { timer } from "./lib";

export const withTimeout = <T>(
  promise: Promise<T>,
  timeout: number
): Promise<T | void> => {
  return Promise.race([promise, timer(timeout)]);
};

const elementIterator = (
  toSet: Element | Element[],
  setCallback: (el: Element) => void
) => {
  if (toSet) {
    if (toSet instanceof Element) {
      setCallback(toSet);
    } else {
      toSet.forEach((el) => setCallback(el));
    }
  }
};

export const hide = (toHide: Element | Element[]): void => {
  elementIterator(toHide, (el: Element) => {
    el.classList.add("bn-hide");
  });
};

export const show = (toShow: Element | Element[]): void => {
  elementIterator(toShow, (el: Element) => {
    el.classList.remove("bn-hide");
  });
};

export const toggle = (
  toToggle: Element | Element[],
  className: string
): void => {
  elementIterator(toToggle, (el: Element) => {
    el.classList.toggle(className);
  });
};

export function waitForElement<T extends Element = HTMLElement>(
  selector: string,
  parentElement: Element | null = null
): Promise<T> {
  return new Promise<T>((resolve) => {
    if (parentElement && parentElement.querySelector(selector)) {
      resolve(parentElement.querySelector(selector) as T);
    } else if (document.querySelector(selector)) {
      resolve(document.querySelector(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (parentElement && parentElement.querySelector(selector)) {
        resolve(parentElement.querySelector(selector) as T);
      } else if (document.querySelector(selector)) {
        resolve(document.querySelector(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function waitForElementWithId<T extends HTMLElement = HTMLElement>(
  selector: string
): Promise<T> {
  return new Promise<T>((resolve) => {
    if (document.getElementById(selector)) {
      resolve(document.getElementById(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function getElement<T extends Element = HTMLElement>(
  selector: string,
  parentElement: Element | null = null
): T {
  if (parentElement && parentElement.querySelector(selector)) {
    return parentElement.querySelector(selector) as T;
  }
  if (document.querySelector(selector)) {
    return document.querySelector(selector) as T;
  }
  throw new Error(`No element found for selector ${selector}`);
}

export function getElementWithId<T extends HTMLElement = HTMLElement>(
  selector: string
): T {
  if (document.getElementById(selector) !== null) {
    return document.getElementById(selector) as T;
  }
  throw new Error(`No element found for selector ${selector}`);
}

export function getAllElements<T extends Element = HTMLElement>(
  selector: string,
  parentElement: Element | null = null
): T[] {
  if (parentElement && parentElement.querySelectorAll(selector)) {
    return [...parentElement.querySelectorAll<T>(selector)];
  }
  if (document.querySelectorAll(selector)) {
    return [...document.querySelectorAll<T>(selector)];
  }
  throw new Error(`No elements found for selector ${selector}`);
}

export const isMobileScreenSize = (): boolean =>
  window.matchMedia("screen and (max-width: 992px)").matches;

export const getURLParams = (url: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(url);
};
