type OnScrollCallBack = (inView: boolean, target: HTMLElement) => void;
type CallBackOptions = {
  root: null | HTMLElement;
  rootMargin: string;
  threshold: number;
  callBack: OnScrollCallBack;
};

export interface CallbackOnScrollSettings {
  targets: HTMLElement | NodeListOf<HTMLElement>;
  options: CallBackOptions;
}

export default class CallbackOnScroll {
  settings: CallbackOnScrollSettings;

  observer: IntersectionObserver;

  constructor(settings: CallbackOnScrollSettings) {
    this.settings = settings;
  }

  init(): void {
    this.observer = new IntersectionObserver(
      this.handleIntersect,
      this.settings.options
    );

    if (this.settings.targets instanceof HTMLElement) {
      this.observer.observe(this.settings.targets);
    } else {
      this.settings.targets.forEach((el) => {
        this.observer.observe(el);
      });
    }
  }

  handleIntersect = (entries: Array<IntersectionObserverEntry>): void => {
    entries.forEach((entry) => {
      this.settings.options.callBack(
        entry.isIntersecting,
        entry.target as HTMLElement
      );
    });
  };
}
