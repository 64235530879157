import Swiper, { Autoplay, Lazy, Mousewheel, Pagination } from "swiper";
import CallbackOnScroll from "../../shared/callbackOnScroll";
import AnimateNumbers from "../../shared/animateNumbers";
import "../../modules/success_stories";
import "../../shared/components/bubbles/bubbles";
import "../../modules/member_journey";

Swiper.use([Autoplay, Pagination, Mousewheel, Lazy]);

class Homepage {
  static animateOnScroll() {
    new CallbackOnScroll({
      targets: document.querySelectorAll(".js-scroll"),
      options: {
        root: null,
        rootMargin: "100px",
        threshold: 1.0,
        callBack: (inView, target) => {
          if (inView) {
            target.classList.add("scrolled");
          }
        },
      },
    }).init();
  }

  static animateNumbers() {
    new AnimateNumbers().init();
  }

  static animateScrollArrow() {
    const SHOW_SCROLL_ARROW_AFTER_MS = 4000;
    const scrollArrow = document.querySelector(".hero__scroll-arrow");

    setTimeout(() => {
      scrollArrow?.classList.add("hero__scroll-arrow--show");
    }, SHOW_SCROLL_ARROW_AFTER_MS);

    window.onscroll = () => {
      scrollArrow?.classList.remove("hero__scroll-arrow--show");
    };
  }

  static init() {
    Homepage.animateOnScroll();
    Homepage.animateNumbers();
    Homepage.animateScrollArrow();
  }
}

Homepage.init();
