import Swiper, { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import swiperBaseOptions from "../shared/swiperOptions";
import { getAllElements, waitForElement } from "../shared/utilities";

class MemberJourney {
  element: SVGElement;

  stops: Array<SVGPathElement>;

  stops3D: Array<SVGPathElement>;

  icons: Array<SVGPathElement>;

  swiper: Swiper;

  showSlide(index: number) {
    this.swiper.slideTo(index);

    this.stops.forEach((aStop: SVGPathElement, i: number) => {
      if (index !== i && aStop.classList.contains("up")) {
        aStop.classList.remove("up");
        aStop.classList.add("down");

        this.stops3D[i].classList.remove("up");
        this.stops3D[i].classList.add("down");

        this.icons[i].classList.remove("up");
        this.icons[i].classList.add("down");
      }
    });
    if (!this.stops[index].classList.contains("up")) {
      this.stops[index].classList.remove("down");
      this.stops[index].classList.add("up");

      this.stops3D[index].classList.remove("down");
      this.stops3D[index].classList.add("up");

      this.icons[index].classList.remove("down");
      this.icons[index].classList.add("up");
    }
  }

  init = (svg: SVGElement) => {
    this.swiper = new Swiper("#member-journey__swiper__container", {
      ...swiperBaseOptions,
      direction: "vertical",
      speed: 1000,
      spaceBetween: 30,
      autoHeight: true,
      touchReleaseOnEdges: true,
      edgeSwipeDetection: true,
      centeredSlides: true,
      pagination: {
        el: document.querySelector(
          ".member-journey__swiper__pagination"
        ) as HTMLElement,
        clickable: true,
      },
    });

    this.swiper.on("slideChange", () => {
      this.showSlide(this.swiper.activeIndex);
    });

    this.element = svg;
    this.stops = getAllElements<SVGPathElement>(
      ".stop",
      this.element
    ).reverse();
    this.stops3D = getAllElements<SVGPathElement>(
      ".stopBase",
      this.element
    ).reverse();
    this.icons = getAllElements<SVGPathElement>(
      ".icon",
      this.element
    ).reverse();

    this.stops.forEach((stop: SVGPathElement, index: number) => {
      stop.addEventListener("click", () => {
        this.showSlide(index);
      });
    });
    this.showSlide(0);
    this.element.classList.add("bn-fade-block");
  };
}
Swiper.use([Autoplay, Mousewheel, Navigation, Pagination]);
waitForElement<SVGElement>(".member-journey").then((el: SVGElement) => {
  new MemberJourney().init(el);
});
