import { isMobileScreenSize } from "./utilities";

/* eslint no-param-reassign: ["error", { "props": false }] */

export default class AnimateNumber {
  elements: NodeListOf<HTMLElement>;

  numbers: Array<Array<number>>;

  observer: IntersectionObserver;

  target: NodeListOf<HTMLElement>;

  isMobile: () => boolean;

  init(): void {
    this.elements = document.querySelectorAll(".js-number");
    this.isMobile = isMobileScreenSize;
    this.saveAsNumbers();
    this.resetValues();
    this.setUpObserver();
  }

  private setUpObserver() {
    this.observer = new IntersectionObserver(this.handleIntersect, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    this.target = document.querySelectorAll(".jobs-and-interns-discover__card");

    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }

  private handleIntersect = (
    entries: Array<IntersectionObserverEntry>
  ): void => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        this.animateSprite();
        this.target.forEach((el) => {
          this.observer.unobserve(el);
        });
      }
    });
  };

  // split the number
  private saveAsNumbers(): void {
    this.numbers = [];

    // save numbers to be animated
    this.elements.forEach((el) => {
      const arr = [];

      for (let i = 0; i < el.innerHTML.length; i += 1) {
        arr.push(Number(el.innerHTML[i]));
      }
      this.numbers.push(arr);
    });
  }

  private resetValues(): void {
    this.elements.forEach((el, index) => {
      // add as many 0s as there are digits in the number to be revealed
      const multiplier: number = this.numbers[index].length;
      el.innerHTML = AnimateNumber.getPlaceholder(multiplier);
    });
  }

  static getPlaceholder = (multiplier: number): string => {
    let str = "";
    for (let i = 0; i < multiplier; i += 1) {
      str += "0";
    }
    return str;
  };

  // animate through sprite 0-9 until meet condition
  private animateSprite(): void {
    const ROW_HEIGHT = 3;
    const KERNING = 2;
    const SPRITE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    this.elements.forEach((el, index) => {
      const noOfColumns = this.numbers[index].length;
      el.innerHTML = "";
      el.style.width = `${noOfColumns * KERNING}rem`;

      this.numbers[index].forEach((targetNumber, i) => {
        const spriteColumn = document.createElement("SPAN");

        spriteColumn.classList.add("jobs-and-interns-discover__sprite-column");

        spriteColumn.style.left = `${i * KERNING}rem`;

        SPRITE.forEach((digit) => {
          const span = document.createElement("SPAN");
          span.style.height = `${ROW_HEIGHT}rem`;
          span.append(String(digit));
          spriteColumn.append(span);
        });

        setTimeout(() => {
          spriteColumn.style.transition = `transform ${
            (i + 1) * 0.4
          }s ease-out`;
          spriteColumn.style.animationDelay = `${(i + 1) * 0.2}s`;
          spriteColumn.style.transform = `translateY(-${
            ROW_HEIGHT * targetNumber
          }rem)`;
        }, 100);

        el.append(spriteColumn);
      });
    });
  }
}
